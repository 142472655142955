<template>
  <div class="noscrollmain">
    <common-top
      title="微力指数"
      :isShowSearch="isShowSearch"
      @search="showModal = true"
    ></common-top>

    <Modal v-model="showDetailStatus" fullscreen>
      <p slot="header" style="text-align: center">
        <span>{{ detailInfo["id"] }}</span>
      </p>

      <div>
        <!-- 环比 -->
        <div style="box-shadow: 0 1px 6px #afb1b6">
          <huan-bi-tong-bi-com
            :optionHuanBiRadio="optionHuanBiRadio"
            :optionTongBiRadio="optionTongBiRadio"
            :detailInfo="detailInfo"
            :showVal="true"
            ref="huanbiRef"
          ></huan-bi-tong-bi-com>
        </div>
      </div>

      <div slot="footer">
        <Button size="large" long @click="showDetailStatus = false"
          >确定</Button
        >
      </div>
    </Modal>

    <dept-choose-com
      :defaultEndDate="endDate"
      :defaultStartDate="startDate"
      :showModal="showModal"
      @chooseDept="chooseDept"
      :isShowDept="isShowDept"
      @cancel="showModal = false"
      :isShowTime="true"
      :showChejian="true"
    ></dept-choose-com>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <desc-com
      :title="showLoad ? '统计中...' : ''"
      :startDate="startDate"
      :endDate="endDate"
      :isFilter="true"
      :sortList="sortList"
      @sortData="sortData"
    ></desc-com>

    <div v-show="showList && isUserPm">
      <list-com
        @chooseListItem="chooseListItem"
        :columns="columns"
        :title="title"
        :showList="dataList"
        :showLoad="showLoad"
        :descWidth="true"
      ></list-com>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
    <div class="Instructions" @click="goInstructions()">
      <svg
        t="1724901708090"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4839"
        width="50"
        height="50"
      >
        <path
          d="M513.3 1023.9c-36.5 0-73.2-3.9-109.7-11.8C270.1 983 155.8 903.7 81.8 788.7-31.8 611.9-26.7 387 94.9 215.6c14.7-20.7 43.4-25.5 64-10.9 20.7 14.7 25.5 43.3 10.9 64C70 409.4 65.8 594 159 739c60.7 94.4 154.5 159.5 264.1 183.3 109.6 23.9 222 3.5 316.3-57.2 194.8-125.3 251.4-385.7 126.1-580.5-125.2-194.8-385.6-251.3-580.4-126-21.3 13.7-49.7 7.5-63.4-13.8-13.7-21.3-7.5-49.7 13.8-63.4C472.8-71.3 790.2-2.4 942.8 235c152.6 237.4 83.7 554.7-153.6 707.4-83.6 53.7-178.8 81.5-275.9 81.5z"
          fill="#009443"
          p-id="4840"
        ></path>
        <path
          d="M511.1 674.8m-58.6 0a58.6 58.6 0 1 0 117.2 0 58.6 58.6 0 1 0-117.2 0Z"
          fill="#009443"
          p-id="4841"
        ></path>
        <path
          d="M511.9 564.7c-25.4 0-45.9-20.6-45.9-45.9v-177c0-25.4 20.6-45.9 45.9-45.9 25.4 0 45.9 20.6 45.9 45.9v177c0.1 25.3-20.5 45.9-45.9 45.9z"
          fill="#009443"
          p-id="4842"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
// 单位名称 + 单位人数 + 单位关注人 + 到达率
// 原创率 推文效率 阅读效率 转发率 跟评率 点赞率 优质跟评率
// 到达率得分  原创率得分 推文率得分 阅读率得分 转发率得分 跟评率得分 点赞率得分 优质跟评率得分
// 微力指数
// @ is an alias to /src
import _ from "lodash";
import moment from "moment";
import {
  wxStatsConPost,
  getUserPm,
  getRadio,
  getRadioDate,
  getYearOnYearBasisDate,
  getOptBar,
} from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listCom from "../../components/listCom.vue";
import deptChooseCom from "../../components/deptChooseCom.vue";
import noPassCom from "../../components/noPassCom.vue";
import descCom from "../../components/descComAndSort.vue";
import huanBiTongBiCom from "../../components/huanBiTongBiCom.vue";

import { procWeili } from "./weiliLib";

export default {
  name: "deptWeili",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,
    deptChooseCom,
    noPassCom,
    descCom,
    huanBiTongBiCom,
  },
  data () {
    return {
      optionHuanBiRadio: {},
      optionTongBiRadio: {},

      filterNameArr: [],
      isShowSearch: false, // 是否显示搜索框
      isUserPm: true, // 是否有权限
      isShowDept: false, // 超管部门选择

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级
      startDate: "2022-01-01",
      endDate: "2022-02-20",

      showDetailStatus: false,
      detailInfo: {}, // 单位详情
      // 单位名称 + 单位人数 + 单位关注人 + 到达率
      // 原创率 推文效率 阅读效率 转发率 跟评率 点赞率 优质跟评率
      // 到达率得分  原创率得分 推文率得分 阅读率得分 转发率得分 跟评率得分 点赞率得分 优质跟评率得分
      // 微力指数
      title: "单位",
      columns: [
        {
          name: "到达率",
          value: "followRate",
        },
        {
          name: "原创率",
          value: "yuanchuangRate",
          color: "#3d750d",
        },
        {
          name: "推文效率",
          value: "pubContentRate",
          color: "#f90",
        },
        {
          name: "阅读效率",
          value: "countRate",
          color: "#5080ba",
        },
        {
          name: "转发率",
          value: "shareRate",
          color: "#8f0505",
        },
        {
          name: "跟评率",
          value: "commentRate",
        },
        {
          name: "点赞率",
          value: "likeRate",
        },
        {
          name: "优质跟评率",
          value: "commentViewRate",
        },
        {
          name: "到达得分",
          value: "followScore",
        },
        {
          name: "原创得分",
          value: "yuanchuangScore",
        },
        {
          name: "推文得分",
          value: "pubContentScore",
        },
        {
          name: "阅读得分",
          value: "countScore",
        },
        {
          name: "转发得分",
          value: "shareScore",
        },
        {
          name: "跟评得分",
          value: "commentScore",
        },
        {
          name: "点赞得分",
          value: "likeScore",
        },
        {
          name: "优质跟评",
          value: "commentViewScore",
        },
        {
          name: "微力指数",
          value: "weiliScore",
        },
      ],
      sortList: [
        // 默认降序 desc  可选升序 asc
        { name: "到达得分", value: "followScore", order: "desc" },
        { name: "原创得分", value: "yuanchuangScore", order: "desc" },
        { name: "推文得分", value: "pubContentScore", order: "desc" },
        { name: "阅读得分", value: "countScore", order: "desc" },
        { name: "转发得分", value: "shareScore", order: "desc" },
        { name: "跟评得分", value: "commentScore", order: "desc" },
        { name: "点赞得分", value: "likeScore", order: "desc" },
        { name: "优质跟评得分", value: "commentViewScore", order: "desc" },
        { name: "微力指数", value: "weiliScore", order: "desc" },

        // { name: "环比增长", value: "环比", order: "desc" },
      ],
      showList: true,
      deptData: [],
      dataList: [],
      ratioData: [],
      showLoad: false,
      deptType: "单位",

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },
  methods: {
    goInstructions () {
      this.$router.push({
        path: "/weiliInstructions",

      });
    },
    // 请求全部部门列表、关注人数
    async getDeptStatus () {
      let _query = {
        type: "status",
      };
      if (this.deptIdArr.length > 0) {
        _query["deptIdArr"] = this.deptIdArr;
        _query["deptLevel"] = this.deptLevel;
      }
      const ret = await wxStatsConPost(
        "/services/gttt-wxstats-mgt/tj/tj_wbl",
        _query
      );
      if (ret.errMsg) {
        this.dailogshow = true;
        this.dailogoptions.content = ret.errMsg;
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }

      this.filterNameArr = ret.filterNameArr;
      let fillStatus = false;
      // 增加过滤数据 非领导班子 站段4级管理员
      if (!_.isEmpty(this.filterNameArr)) {
        fillStatus = true;
      }

      let _dataList = [];
      const deptList = ret.ret ? ret.ret : [];

      for (const iterator of deptList) {
        if (fillStatus) {
          if (this.filterNameArr.indexOf(iterator["单位"]) == -1) {
            continue;
          }
        }
        _dataList.push(iterator);
      }

      return _dataList;
    },
    // 统计站段的推文数、阅读数
    async getContentData (query) {
      const ret = await wxStatsConPost(
        "/services/gttt-wxstats-mgt/weili/getWeiliList",
        query
      );
      this.filterNameArr = ret.filterNameArr;
      return _.get(ret, ["ret"], {});
    },

    // 高级搜索选项选择部门
    chooseDept (emitData) {
      if (emitData.type == "单位") {
        this.deptLevel = 2;
      } else {
        this.deptLevel = 3;
      }
      this.deptIdArr = emitData.deptIdArr;
      this.startDate = emitData.startDate;
      this.endDate = emitData.endDate;

      this.showModal = false;
      this.initData();
    },
    // 请求数据
    async initData () {
      try {
        this.showLoad = true;
        // 请求全部部门列表、关注人数
        const allDeptInfo = await this.getDeptStatus();
        // 统计站段的推文数、阅读数
        const _query = {
          startDate: this.startDate,
          endDate: this.endDate,
        };
        if (this.deptIdArr.length > 0) {
          _query["deptIdArr"] = this.deptIdArr;
          _query["deptLevel"] = this.deptLevel;
        }
        const zdTjData = await this.getContentData(_query);
        // 合并数据，计算各种率
        let alldays = moment(this.endDate).diff(moment(this.startDate), "days"); // 所选时间段总天数
        // 计算方式有误，需要加一
        alldays = alldays + 1;

        this.dataList = await procWeili(allDeptInfo, zdTjData, alldays);
        this.showLoad = false;

        // this.getRadioAndYearOnYearData(_query, allDeptInfo);
      } catch (error) {
        console.log("=--------error", error);
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },

    chooseListItem (item) {
      // console.log("-------", item);
      this.detailInfo = item;
      // this.showDetailStatus = true;

      // this.optionHuanBiRadio = getOptBar(
      //   "环比趋势图",
      //   "微力指数",
      //   [this.detailInfo["上期数"], this.detailInfo["本期数"]],
      //   ["上期数", "本期数"]
      // );

      // this.optionTongBiRadio = getOptBar(
      //   "同比趋势图",
      //   "微力指数",
      //   [this.detailInfo["同期数"], this.detailInfo["本期数"]],
      //   ["同期数", "本期数"]
      // );
      // this.$nextTick(() => {
      //   this.$forceUpdate();
      // });
    },
    // 数据排序
    sortData (data) {
      if (data && data.value) {
        if (data.order == "aes") {
          // 降序
          this.dataList = _.sortBy(this.dataList, [data.value]);
        } else {
          // 升序
          this.dataList = _.reverse(_.sortBy(this.dataList, [data.value]));
        }

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },

    /******************** 环比/同比 */
    async commonRatioDataFun (query, type, allDeptInfo) {
      try {
        let _query = query;
        // 统计站段的推文数、阅读数
        //  计算周期
        let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
        if (type == "tongbi") {
          const dateObj = getYearOnYearBasisDate(this.startDate, this.endDate);
          startDate = dateObj.startDate;
          endDate = dateObj.endDate;
        }

        _query["startDate"] = startDate;
        _query["endDate"] = endDate;

        const zdTjData = await this.getContentData(_query);
        // 合并数据，计算各种率
        alldays = moment(endDate).diff(moment(startDate), "days"); // 所选时间段总天数
        alldays = alldays + 1;
        return await procWeili(allDeptInfo, zdTjData, alldays);
      } catch (error) {
        console.log("-ratioDataData---------", error);
        return [];
      }
    },

    dealCommonRadioData (data, type, typeVal) {
      for (const iterator of this.dataList) {
        // 计算环比差
        const _huanBiData = _.find(data, (o) => {
          return o.deptId == iterator.deptId;
        });

        const weiliScore = _.get(_huanBiData, ["weiliScore"], 0);
        // 环比增长率=（本期数-上期数）/上期数×100%
        iterator[type] = getRadio(iterator["weiliScore"], weiliScore);
        iterator[typeVal] = weiliScore;
        iterator["本期数"] = iterator["weiliScore"]; // 增加本期数
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    async getRadioAndYearOnYearData (query, allDeptInfo) {
      try {
        const huanbiData = await this.commonRatioDataFun(
          query,
          "huanbi",
          allDeptInfo
        );
        const tongbiData = await this.commonRatioDataFun(
          query,
          "tongbi",
          allDeptInfo
        );

        this.dealCommonRadioData(huanbiData, "环比增长", "上期数");
        this.dealCommonRadioData(tongbiData, "同比增长", "同期数");
      } catch (error) {
        console.log("------", error);
      }
    },
  },
  async created () {
    this.startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");

    const userPm = getUserPm();
    if (userPm) {
      this.isShowSearch = true;
      if (userPm.pmValue && userPm.pmSuper) {
        this.isShowDept = true;
      }
      this.initData();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped>
.Instructions {
  position: fixed;
  z-index: 100;
  bottom: 5%;
  right: 5%;
}
</style>
